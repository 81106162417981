.main-left-tree-instance[data-v-6a62665c] {
  height: calc(100% - 50px) !important;
}
.main-left-tree-instance[data-v-6a62665c] .el-tree-node__expand-icon {
    display: none;
}
.custom-card[data-v-6a62665c] .el-card__body {
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
[data-v-6a62665c] .el-tree-node__content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.custom-tree-node[data-v-6a62665c] {
  max-width: 94%;
}
.custom-tree-node .custom-tree-node-title[data-v-6a62665c] {
    max-width: 70%;
    overflow: hidden;
}
.left-tree-top-button[data-v-6a62665c] {
  position: absolute;
  right: 2%;
}
.empty-img[data-v-6a62665c] {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  position: relative;
}
.empty-img img[data-v-6a62665c] {
    display: block;
    margin: auto;
    margin-top: 20%;
}
.empty-img p[data-v-6a62665c] {
    z-index: 1;
    margin-top: -30px;
    text-align: center;
    background-color: #fff;
    color: #666;
    font-size: 24px;
    font-weight: 600;
}
