.bottom-line[data-v-ff519ade] {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 14px 0;
}
.right-con[data-v-ff519ade] {
  width: 100%;
  height: 100%;
  background-color: #f5f6fd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0.75vw 1.5vw;
  overflow-y: auto;
}
.right-con .mian[data-v-ff519ade] {
    width: 100%;
}
.right-con .mian h1[data-v-ff519ade] {
      width: 100%;
      height: 1.4vw;
}
.right-con .mian h1 img[data-v-ff519ade] {
        width: 1.3vw;
        height: 1.3vw;
        margin-right: 10px;
}
.right-con .mian h1 span[data-v-ff519ade] {
        vertical-align: bottom;
        font-size: 1.3vw;
        font-weight: 550;
}
.right-con .mian ul[data-v-ff519ade] {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      margin-bottom: 1vw;
}
.right-con .mian ul li[data-v-ff519ade] {
        font-family: Source Han Sans CN;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 1.3vw 1.1vw 0vw 1.1vw;
        width: 31.5%;
        margin-left: 1vw;
        height: 9vw;
        -webkit-box-shadow: 0vw 0vw 10px 0vw rgba(165, 192, 237, 0.6);
                box-shadow: 0vw 0vw 10px 0vw rgba(165, 192, 237, 0.6);
        border-radius: 10px;
        position: relative;
        margin-bottom: 1vw;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
}
.right-con .mian ul li[data-v-ff519ade]:hover {
          -webkit-transform: translateY(-2px);
                  transform: translateY(-2px);
          -webkit-box-shadow: 4px 4px 10px 2px rgba(165, 192, 237, 0.9);
                  box-shadow: 4px 4px 10px 2px rgba(165, 192, 237, 0.9);
}
.right-con .mian ul li .li-span[data-v-ff519ade] {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 1vw;
          padding: 0.1vw 0.7vw;
          border-radius: 0 10px 0 10px;
}
.right-con .mian ul li .li-span-edit[data-v-ff519ade] {
          color: #609af7;
          background-color: #fff;
}
.right-con .mian ul li .li-span-pub[data-v-ff519ade] {
          color: #58e393;
          background-color: #fff;
}
.right-con .mian ul li .li-span-danger[data-v-ff519ade] {
          color: #609af7;
          background-color: #c2e2ff;
}
.right-con .mian ul li .li-title[data-v-ff519ade] {
          height: 5vw;
          font-size: 1.3vw;
          font-weight: bold;
          color: #fff;
          word-wrap: break-word;
}
.right-con .mian ul li .li-subtitle[data-v-ff519ade] {
          display: none;
          margin-top: 1vw;
          height: 1.2vw;
          font-size: 1.2vw;
          font-weight: 400;
          color: #1f346c;
}
.right-con .mian ul li .li-subtitle-printf[data-v-ff519ade] {
          display: none;
          margin-top: 1vw;
          height: 1.2vw;
          font-size: 1vw;
          font-weight: 400;
          color: #1f346c;
}
.right-con .mian ul li .li-button[data-v-ff519ade] {
          height: 1.5vw;
          width: 100%;
          margin-top: 0.2vw;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
}
.right-con .mian ul li .li-button img[data-v-ff519ade] {
            width: 1.2vw;
            height: 1.2vw;
            cursor: pointer;
}
.right-con .mian ul li .li-button .img-left[data-v-ff519ade]:nth-child(n + 2) {
            margin-left: 2.8vw;
}
